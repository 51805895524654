<template>
  <div class="w-sm-100 w-xs-100">
    <vs-row class="lg-trading-content">

      <vs-row vs-type="flex" vs-w="12" vs-justify="center" class="trading-notification-width" v-if="!isTradeFailed">
        <div class="trading-flex-column w-100 text-center white-card-lg mt-5 mb-5">
          <div class="mt-2">
            <img src="/assets/trading-assets/tick-icon.svg" alt="bank-logo" class="lg-logo"/>
          </div>

          <div class="mb-3 mt-1 text-center">
            <div class="text-success">You have successfully sold</div>
            <vs-row vs-type="flex" vs-justify="center" vs-align="center">
              <cryptoicon :symbol="sellCryptocurrency" size="24" class="m-1"/>
              <strong v-if="sellAmount">
                <span v-if="sellCryptocurrency !=='USDT' ">{{sellCryptocurrency.toUpperCase()}} {{formatCryptoPrice(sellAmount)}}</span>
                <span v-else>{{sellCryptocurrency.toUpperCase()}} {{formatFiatPrice(sellAmount)}}</span>
              </strong>

            </vs-row>
          </div>


          <div class="trading-border-top-dashed">
            <vs-row vs-type="flex" vs-w="12" vs-justify="space-between" vs-align="center" class="p-2">
              <vs-col vs-w="6" vs-type="flex" vs-justify="flex-start">
                <div class="text-small">{{date}}</div>
              </vs-col>
<!--              <vs-col vs-w="6" vs-type="flex" vs-justify="flex-end">-->
<!--                <div class="text-small">09.15 AM</div>-->
<!--              </vs-col>-->
            </vs-row>
          </div>

          <div class="trading-border-top-solid">
            <vs-row vs-type="flex" vs-w="12" vs-justify="flex-start" vs-align="center" class="p-2">
              <div class="trading-flex-column">
                <div class="text-left text-small">
                 YOU SOLD
                </div>
                <div class="text-left fw-600">
                  {{sellFiatCurrency}} {{formatFiatPrice(fiatAmountEquivalent)}}
<!--                 <span v-if="sellCryptocurrency !== 'USDT'">{{sellCryptocurrency.toUpperCase()}} {{formatCryptoPrice(sellAmount)}}</span>-->
<!--                  <span v-else>{{sellCryptocurrency.toUpperCase()}} {{formatFiatPrice(sellAmount)}}</span>-->
                </div>
              </div>
            </vs-row>
          </div>

          <div class="trading-border-top-solid">
            <vs-row vs-type="flex" vs-w="12" vs-justify="flex-start" vs-align="center" class="p-2">
              <div class="trading-flex-column">
                <div class="text-left text-small">
                  RATE
                </div>
                <div class="text-left fw-600">
                  1 {{sellCryptocurrency.toUpperCase()}} = {{sellFiatCurrency.toUpperCase()}} {{formatFiatPrice(sellCryptoExchangeRate)}}
                </div>
              </div>
            </vs-row>
          </div>

<!--          <div class="trading-notification-border-highlighted">-->
<!--            <vs-row vs-type="flex" vs-w="12" vs-justify="flex-start" vs-align="center" class="p-2">-->
<!--              <div class="trading-flex-column">-->
<!--                <div class="text-left text-small">-->
<!--                  CURRENT {{cryptoHoldingCurrency.toUpperCase()}} OWNED-->
<!--                </div>-->
<!--                <div class="text-left fw-600">-->
<!--                  {{cryptoHoldingCurrency.toUpperCase()}} {{formatCryptoPrice(cryptoHolding)}}-->
<!--                </div>-->
<!--                <div class="text-left text-small">-->
<!--                  {{fiatHoldingCurrency.toUpperCase()}} {{formatFiatPrice(fiatHoldingEquivalent)}}-->
<!--                </div>-->
<!--              </div>-->
<!--            </vs-row>-->
<!--          </div>-->
        </div>
        <button class="btn-trading-success w-100" @click="goToMainPortfolioPage()">Back to home</button>
      </vs-row>

      <vs-row vs-type="flex" vs-w="12" vs-justify="center" class="trading-notification-width" v-else>
        <div class="error-wrapper text-center">
          <br>
          <br>
          <br>
          <br>
          <br>
          <br/>
          <h4>Oops ! Seems like there is an error in selling crypto. Please try again or contact us at <a
              href="mailto:support@transcryptglobal.com?subject=Error%20In%20Buying%20Crypto">support@transcryptglobal.com</a>.</h4>
          <br/>
          <br>
          <br>
          <br>
          <br>
          <br>
        </div>
        <button class="btn-trading-success w-100" @click="goToMainPortfolioPage()">Back to home</button>
      </vs-row>

    </vs-row>
  </div>
</template>
<script>

import { CheckIcon } from 'vue-feather-icons'
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'sell-crypto-notification',
  components: {
  // CheckIcon
  },
  data() {
    return {
      mode: process.env.VUE_APP_STAGING_ENV ? null : "production",

      date : null,
      sellAmount: null,
      sellFiatCurrency : "",
      sellCryptocurrency : "",
      fiatAmountEquivalent: 0,
      sellCryptoExchangeRate: 0,

      cryptoHolding : 0,
      fiatHoldingEquivalent : 0,
      cryptoHoldingCurrency : "",
      fiatHoldingCurrency : "",
      isTradeFailed : false,

      debugConsole : false,

    }
  },
  watch: {
    $route() {

    },
  },
  computed: {
    ...mapGetters(["userCryptoBalanceGetter"])
  },
  metaInfo: {
    title: 'TransCrypt - Transfer Form | Send Money Faster with Cryptocurrency',
  },
  mounted() {
    if(this.debugConsole) console.log("params on selling page", this.$route.params);

    let query = this.$route.query;
    if(this.debugConsole) console.log("Get Query in sell page", query);

    if(query.trade_id)
    {
      if(this.debugConsole)  console.log("Trade Id", query.trade_id);
      let tradeId = query.trade_id;

      if(tradeId === 'failed')
      {
        if(this.debugConsole) console.log("Trade ID Failed in sell notification");
        this.isTradeFailed = true;
      }else
      {
        if(this.debugConsole) console.log("Trade ID OK", tradeId);
        this.isTradeFailed = false;
        this.getUserBalances().then(res => {
          if(this.debugConsole)  console.log("Result in getting user balances in sell notif page", res.data);

          this.getTradeDetails(tradeId).then(res => {
            if(this.debugConsole)   console.log("Result in getting trade details in sell notif page");

            this.date = res.data && this.formatDateForHistoryContent(res.data.date);

            this.sellAmount = res.data && res.data.beg_amt;
            this.sellFiatCurrency = res.data && res.data.end_ccy;
            this.sellCryptocurrency = res.data && res.data.beg_ccy;
            this.fiatAmountEquivalent = res.data && res.data.end_amt;
            this.sellCryptoExchangeRate = res.data && res.data.xchange_rate;

            //get the user crypto holding
            let userCryptoHolding = this.userCryptoBalanceGetter && this.userCryptoBalanceGetter.find(item => item.ccy === this.sellCryptocurrency);
            if(this.debugConsole) console.log("User Crypto Holding in sell notif page", userCryptoHolding);


            this.cryptoHolding = userCryptoHolding.bal_amt;
            this.fiatHoldingEquivalent = userCryptoHolding.port_val;
            this.cryptoHoldingCurrency = userCryptoHolding.ccy;
            this.fiatHoldingCurrency = userCryptoHolding.trade_ccy;


          }).catch(err => {
            console.log("error in getting trade details in sell notif page", err);
          })

        }).catch(err => {
          console.log("Error in getting user balances in sell notif page", err);
          this.isTradeFailed = true;
        })
      }


    }







  },

  methods: {
    ...mapActions(["getTradeDetails", "getUserBalances"]),
    goToMainPortfolioPage(){
      this.$router.push('/trading/main')
    }
  }
}

</script>
<style>


</style>